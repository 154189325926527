.wrapper {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f2f2f2;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.crop-title {
    font-size: 24px;
    text-transform: capitalize;
    color: #2E7D32;
    margin-bottom: 20px;
    border-bottom: 2px solid #AED581;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.list {
    padding: 0;
    margin: 0;
}

.list-item {
    list-style: none;
    font-size: 14px;
    background-color: #e9f5eb;
    padding: 15px;
    color: #333;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #81c784;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stage-header {
    color: #388e3c;
    margin: 0;
}

.date-subheader {
    color: #777;
    margin-top: 3px;
    margin-bottom: 3px;
}

.recommendation {
    margin-top: 10px;
    margin-bottom: 2px;
}

.icon {
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    transition: color 0.3s;
}

.icon:hover {
    color: #ec5e67;
}

.back-icon {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
    transition: color 0.3s;
}

.back-icon:hover {
    color: #2E7D32;
}
p {
    color: #333;
}
.spinner {
    font-size: 2em;
    animation: spin 1s infinite linear;
    display: block;
    margin: 20vh auto;  /* Vertically center it on the page */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
