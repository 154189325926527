.home-wrapper {
    font-family: 'Cabin', sans-serif;
  background-color: #EAF3EF;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.spinner {
    font-size: 2em;
    animation: spin 1s infinite linear;
    display: block;
    margin: 20vh auto;  /* Vertically center it on the page */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.navbar {
    height: 12vh;
    display: flex;
    align-items: center;
    background-color: #67CC77;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px gray;
    justify-content: space-between;
    margin: 0%;
    flex: 0 1 auto;
}

.cultura_image_home {
    height: 10vh;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.login {
    height: 8vh;
    margin: 0%;
    margin-right: 20px;
    font-size: 120%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info-wrapper-home {
    background-image: url(../../images/wheat-dark.png);
    margin-top: 0px;
    position: absolute;
    top: 12vh;
    bottom: -9vh; 
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-title {
    margin-top: 0px;
    color: white;
    font-size: 36px;
}

.home-sub {
    color: white;
    font-style: italic;
    font-weight: 300;
}

.home-login {
    margin-top: 20px;
    padding: 12px 48px;  /* Increase padding for better visibility */
    height: 60px;
    background-color: green;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer; 
    margin-left: 5px;
}

.home-register {
    margin-top: 20px;
    padding: 12px 48px;  /* Increase padding for better visibility */
    height: 60px;
    background-color: green;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
}

.home-top-login {
    margin-top: 10px;
    margin-bottom: 10px; 
    height: 7vh;
    width: 9vw;
    background-color: green;
    color: white;
    font-size: 2vh;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 2vw;
}