html, body {
    background-color: #F5F7F9;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .dashboard-wrapper {
    font-family: 'Cabin', sans-serif;
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 20%;
    background-color: #2E7D32;
    color: #FFFFFF;
    padding: 2% 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sidebar li:hover {
    background-color: #1B5E20;
  }
  
  .main-content {
    width: 80%;
    padding: 2%;
    overflow-y: auto;
  }




  .crop-card {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    width: calc(30% - 16px);  /* Subtracting the total horizontal margins */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: inline-block; /* To display multiple cards in a row */
    vertical-align: top;
  }
  
  .crop-name {
    font-weight: bold;
    font-size: 1.5em;
    text-transform: capitalize; /* Capitalize the first letter */
  }
  
  .crop-stage, .crop-date {
    margin-top: 10px;
  }  
  .crop-link {
    text-decoration: none;
    color: #2E7D32;
    font-weight: bold;
  }
  
  .crop-form, .other-feature {
    background-color: #E9ECEF;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    text-align: center;
  }
  
  /* ... (rest of the styles) */
  
  
  .crop-selector, .input-field {
    width: 100%;
    height: 50px;
    padding: 12px;
    background-color: #FFFFFF;
    border: 1px solid #BDC3C7;
    border-radius: 4px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .submit-button {
    background-color: #4A90E2;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #357ABD;
  }
  p {
    color: #2f2f2f;
  }


.spinner {
    font-size: 2em;
    animation: spin 1s infinite linear;
    display: block;
    margin: 20vh auto;  /* Vertically center it on the page */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  