/* neomorphic.css */
.neomorphic-card {
  background-color: #f0f0f000;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
}

.neomorphic-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.neomorphic-card-header {
  background-color: #001529;
  border-radius: 15px;
  margin-bottom: 8px;
  color: white;
}
.neomorphic-card-body {
  background-color: #f0f0f082;
  border-radius: 15px;
}
