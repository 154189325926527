.section {
  height: 100vh;
}

.container {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.col-md-9,
.col-lg-7,
.col-xl-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0;
  border-radius: 0.25rem;
}

.text-white {
  color: #fff !important;
}

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.shadow-4-strong {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125),
    0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
}

.card-header,
.card-body {
  padding: 1.25rem;
  border: 0;
}

.card-header {
  margin-bottom: 0 !important;
}

.text-center {
  text-align: center !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.h-100 {
  height: 100% !important;
}

.col-2,
.col-lg-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.d-block {
  display: block !important;
}

.px-5 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}
:where(.css-dev-only-do-not-override-2q8sxy).antdmodalweather
  .antdmodalweather-footer
  .ant-btn
  + .ant-btn:not(.ant-dropdown-trigger) {
  background-color: #5ab963 !important;
}

.cardContainer {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hourly .card {
  position: relative;
  width: 220px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  background-color: rgba(65, 65, 65, 0.308);
  border: 1px solid rgba(255, 255, 255, 0.089);
  cursor: pointer;
}

.hourly .city {
  font-weight: 700;
  font-size: 0.9em;
  letter-spacing: 1.2px;
  color: white;
}

.hourly .weather {
  font-weight: 500;
  font-size: 0.7em;
  letter-spacing: 1.2px;
  color: rgb(197, 197, 197);
}

.hourly .temp {
  font-size: 1.8em;
  color: white;
}

.hourly .minmaxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hourly .min,
.hourly .max {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 20px;
  gap: 4px;
}

.hourly .max {
  align-items: flex-start;
  border-left: 2px solid white;
}

.hourly .maxHeading,
.hourly .minHeading {
  font-size: 0.7em;
  font-weight: 600;
  color: white;
}

.hourly .maxTemp,
.hourly .minTemp {
  font-size: 0.6em;
  font-weight: 500;
  color: rgb(197, 197, 197);
}
