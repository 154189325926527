html {
  background-color: #eaf3ef;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f3f4f6;
}

.cultura_image {
  max-width: 350px;
  height: 100px;
}

.login-container form {
  width: 350px; /* Set the form width */
  background: #ffffff;
  padding: 40px; /* Increased padding */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center-align the text */
}

.login-container div {
  margin-bottom: 20px; /* Add spacing between form elements */
}

.login-container label {
  display: block; /* Make label take the full width */
  margin-bottom: 8px; /* Add some space below the label */
  font-weight: bold;
  text-align: left; /* Align the labels to the left */
}

.login-container input {
  display: block;
  box-sizing: border-box;
  width: 100%;

  margin: 5px 0 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-align: left;
}

.login-page-button {
  margin-top: 20px;
  padding: 12px 48px; /* Increase padding for better visibility */
  height: 60px;
  background-color: green;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer; /* Make it clear that it's clickable */
}

.login-page-button:hover {
  background-color: #0056b3; /* Add hover effect */
}

p {
  color: red;
}
.spinner {
  font-size: 2em;
  animation: spin 1s infinite linear;
  display: block;
  margin: 20vh auto; /* Vertically center it on the page */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
